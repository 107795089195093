<template>
  <div class="main-content">
    <div class="form-wrap">
      <v-text-field
        v-model="layouts.data.currentPassword"
        label="Enter Current Password"
        :append-icon="currentPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
        :type="currentPasswordVisible ? 'text' : 'password'"
        @click:append="currentPasswordVisible = !currentPasswordVisible"
        required
        class="mb-5"
      ></v-text-field>

      <v-text-field
        v-model="layouts.data.newPassword"
        :append-icon="newPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
        :type="newPasswordVisible ? 'text' : 'password'"
        @click:append="newPasswordVisible = !newPasswordVisible"
        label="Enter New Password"
        required
        class="mb-5"
      ></v-text-field>

      <v-text-field
        v-model="layouts.data.confirmPassword"
        :append-icon="confirmPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
        :type="confirmPasswordVisible ? 'text' : 'password'"
        @click:append="confirmPasswordVisible = !confirmPasswordVisible"
        label="Confirm New Password"
        required
        class="mb-5"
      ></v-text-field>

      <v-card flat height="190">
        <check-password-strength
          v-model="layouts.data.newPassword"
          :confirmPassword="layouts.data.confirmPassword"
          @strengthResult="updateStrengthResult"
        ></check-password-strength>
      </v-card>

      <div class="pt-5 mt-5 d-flex justify-space-between">
        <web-btn
          :height="50"
          width="100%"
          :disabled-control="!(layouts.data.passwordStrength
          && layouts.data.newPassword === layouts.data.confirmPassword
          && assists.tool.isNotEmpty(layouts.data.newPassword)
          && assists.tool.isNotEmpty(layouts.data.confirmPassword))"
          @click="changePassword"
        >Change Password
        </web-btn>
      </div>
    </div>
  </div>
</template>

<script>
import WebBtn from '@/components/base/WebBtn'
import { AccountApi } from '@/api'
import { Crypto, Cache, Popup, Tool } from '@/assets/js/util'
import CheckPasswordStrength from '@/components/common/CheckPasswordStrength'

export default {
  name: 'ChangePassword',
  components: {
    WebBtn,
    CheckPasswordStrength
  },
  data () {
    return {
      valid: true,
      currentPasswordVisible: false,
      newPasswordVisible: false,
      confirmPasswordVisible: false,
      assists: {
        cache: new Cache(),
        popup: new Popup(),
        tool: new Tool()
      },
      layouts: {
        data: {
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
          passwordStrength: false
        },
        rules: {
          emptyVerify: [v => !!v || 'Password can not be empty'],
          passwordRules: [
            v => !!v || 'Password can not be empty',
            v => v.length >= 8 || 'Password should be 8 or more characters.',
            value => /[a-z]/.test(value) || 'Password has Upper & Lowercase letters.',
            value => /[A-Z]/.test(value) || 'Password has Upper & Lowercase letters.',
            value => /\d/.test(value) || 'Password should be at least one number.',
            value => /^(?!.*(.)\1\1).*$/.test(value) || 'Password has too many consecutive identical characters.'
          ],
          sameVerify: [v => (v === this.layouts.data.newPassword) || 'The two passwords do not match']
        }
      }
    }
  },
  methods: {
    changePassword () {
      const request = {
        oldPassword: Crypto.md5(this.layouts.data.currentPassword),
        newPassword: Crypto.md5(this.layouts.data.newPassword)
      }
      AccountApi.changePassword(
        request,
        () => {
          this.assists.popup.popupForSuccess(
            'Password changed successfully.',
            () => {
              this.assists.cache.clear()
              this.$router.push('/sign-in')
            }
          )
        },
        (err) => {
          this.assists.popup.popupForError(err.message)
        }
      )
    },
    updateStrengthResult (result) {
      this.layouts.data.passwordStrength = result
    },
    onClickBack () {
      this.$router.push('/home')
    }
  }
}
</script>

<style lang="sass" scoped>
.main-content
  display: flex
  justify-content: center
  align-items: center
  height: 80%

.form-wrap
  width: 500px
</style>
